import React from 'react';

function Login() {
  const handleGoogleLogin = () => {
    window.location.href = '/auth/google';
  };

  return (
    <div>
      <h2>Effettua il login</h2>
      <button onClick={handleGoogleLogin}>Accedi con Google</button>
    </div>
  );
}

export default Login;
