import React, { useState } from 'react';
import axios from 'axios';

function Chat() {
  const [prompt, setPrompt] = useState('');
  const [responses, setResponses] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!prompt) return;

    try {
      const res = await axios.post('/api/openai', { prompt });
      setResponses([...responses, res.data.choices[0].message.content]);
      setPrompt('');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2>ChatGPT</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Scrivi il tuo messaggio..."
        />
        <button type="submit">Invia</button>
      </form>
      <div>
        {responses.map((response, index) => (
          <p key={index}>{response}</p>
        ))}
      </div>
    </div>
  );
}

export default Chat;
